import React, {useState, useEffect} from "react";
import {
    withRouter,
    useParams,
    useLocation,
    Link,
    useHistory,
} from "react-router-dom";
import axios from "axios";
import Context from "./../../context";
import SidebarCategories from "./../../components/Sidebar/Categories";
import SidebarFeatured from "./../../components/Sidebar/Featured";
import RecentArticles from "../../components/RecentPosts";
import PageHeading from "./../../components/PageHeading";
import EmailModal from "./../../components/EmailModal";

// Icons
import Uroclips from "./../../assets/img/icons/heading/uroclips-icon.svg";
import Article from "./../../assets/img/icons/heading/article-icon.svg";
import Infography from "./../../assets/img/icons/heading/infographic-icon.svg";
import Rutin from "./../../assets/img/icons/heading/exercise-icon.png";
import Plan from "./../../assets/img/icons/heading/diet-icon.svg";
import Clinical from "./../../assets/img/icons/heading/clinical-cases-icon.svg";
import Calculator from "./../../assets/img/icons/heading/calculator-icon.svg";

import Dot from "./../../assets/img/icons/pica.svg";
import ClapGray from "../../assets/img/icons/clap-gray.svg";
import ClapRed from "../../assets/img/icons/clap-red.svg";
import PlayIcon from "../../assets/img/icons/play-white.svg";

import "./index.scss";

function Single(props) {
    const {state, dispatch} = React.useContext(Context);
    const [single, setSingle] = useState(null);
    const [isPublic, setisPublic] = useState(null);
    const [error, setError] = useState(null);
    const [openMailModal, setOpenMailModal] = useState(false);
    const [popularity, setPopularity] = useState(0);
    const [pdf, setPDF] = useState(false);
    const [video, setVideo] = useState(false);
    const [votedAlready, setVotedAlready] = useState(false);
    const [sent, setSent] = useState(false);

    let {pathname, key} = useLocation();
    let slug = useParams().slug;
    let history = useHistory();

    useEffect(() => {
        if (pathname.includes("uro-clips")) {
            setError("cargando...");
            axios
                .get(`/clip/slug/${slug}`)
                .then(function (res) {
                    setSingle(Object.values(res.data)[0]);
                    setisPublic(Object.values(res.data)[0].is_public);
                    setPopularity(Object.values(res.data)[0].popularity);
                    setPDF(Object.values(res.data)[0].pdf);
                    setVideo(Object.values(res.data)[0].vimeo);
                    setError(null);
                    dispatch({
                        type: "SET_PAGE_POSTS",
                        payload: {
                            clipIcon: true,
                        },
                    });
                    dispatch({
                        type: "SET_PAGE_INFO",
                        payload: {
                            apiBase: "/clip",
                            apiName: "/uro-clips",
                            apiUrl: `/clip/slug/${slug}`,
                            pageTitle: "Uro clips",
                            pageIcon: Uroclips,
                        },
                    });
                })
                .catch(function (error) {
                    setError("Se ha producido un error, por favor intente mas tarde.");
                });
        }
        if (pathname.includes("infografias")) {
            setError("cargando...");
            axios
                .get(`/infography/slug/${slug}`)
                .then(function (res) {
                    setSingle(Object.values(res.data)[0]);
                    setisPublic(Object.values(res.data)[0].is_public);
                    setPopularity(Object.values(res.data)[0].popularity);
                    setPDF(Object.values(res.data)[0].pdf);
                    setVideo(Object.values(res.data)[0].video);
                    setError(null);
                    dispatch({
                        type: "SET_PAGE_POSTS",
                        payload: {
                            clipIcon: false,
                        },
                    });
                    dispatch({
                        type: "SET_PAGE_INFO",
                        payload: {
                            apiBase: "/infography",
                            apiName: "/infografias",
                            apiUrl: `/infography/slug/${slug}`,
                            pageTitle: "Infografías",
                            pageIcon: Infography,
                        },
                    });
                })
                .catch(function (error) {
                    setError("Se ha producido un error, por favor intente mas tarde.");
                });
        }
        if (pathname.includes("articulos")) {
            setError("cargando...");
            axios
                .get(`/article/slug/${slug}`)
                .then(function (res) {
                    setSingle(Object.values(res.data)[0]);
                    setisPublic(Object.values(res.data)[0].is_public);
                    setPopularity(Object.values(res.data)[0].popularity);
                    setPDF(Object.values(res.data)[0].pdf);
                    setVideo(Object.values(res.data)[0].video);
                    setError(null);
                    dispatch({
                        type: "SET_PAGE_POSTS",
                        payload: {
                            clipIcon: false,
                        },
                    });
                    dispatch({
                        type: "SET_PAGE_INFO",
                        payload: {
                            apiBase: "/article",
                            apiName: "/articulos",
                            apiUrl: `/article/slug/${slug}`,
                            pageTitle: "Artículos",
                            pageIcon: Article,
                        },
                    });
                })
                .catch(function (error) {
                    setError("Se ha producido un error, por favor intente mas tarde.");
                });
        }
        if (pathname.includes("rutinas")) {
            setError("cargando...");
            axios
                .get(`/routine/slug/${slug}`)
                .then(function (res) {
                    setSingle(Object.values(res.data)[0]);
                    setisPublic(Object.values(res.data)[0].is_public);
                    setPopularity(Object.values(res.data)[0].popularity);
                    setPDF(Object.values(res.data)[0].pdf);
                    setVideo(Object.values(res.data)[0].video);
                    setError(null);
                    dispatch({
                        type: "SET_PAGE_POSTS",
                        payload: {
                            clipIcon: false,
                        },
                    });
                    dispatch({
                        type: "SET_PAGE_INFO",
                        payload: {
                            apiBase: "/routines",
                            apiName: "/herramientas/rutinas-de-ejercicios",
                            apiUrl: `/routine/slug/${slug}`,
                            pageTitle: "Rutinas de ejercicios",
                            pageIcon: Rutin,
                        },
                    });
                })
                .catch(function (error) {
                    setError("Se ha producido un error, por favor intente mas tarde.");
                });
        }
        if (pathname.includes("planes")) {
            setError("cargando...");
            axios
                .get(`/plan/slug/${slug}`)
                .then(function (res) {
                    setSingle(Object.values(res.data)[0]);
                    setisPublic(Object.values(res.data)[0].is_public);
                    setPopularity(Object.values(res.data)[0].popularity);
                    setPDF(Object.values(res.data)[0].pdf);
                    setVideo(Object.values(res.data)[0].video);
                    setError(null);
                    dispatch({
                        type: "SET_PAGE_POSTS",
                        payload: {
                            clipIcon: false,
                        },
                    });
                    dispatch({
                        type: "SET_PAGE_INFO",
                        payload: {
                            apiBase: "/plan",
                            apiName: "/herramientas/planes-de-alimentacion",
                            apiUrl: `/plan/slug/${slug}`,
                            pageTitle: "Planes de alimentación",
                            pageIcon: Plan,
                        },
                    });
                })
                .catch(function (error) {
                    setError("Se ha producido un error, por favor intente mas tarde.");
                });
        }
        dispatch({
            type: "TOGGLE_SINGLE",
            payload: {isSingle: true},
        });
    }, [pathname]);

    if (single) {
        if (!single.isPublic && !state.isLogued) {
            history.push("/");
        }
    }

    //   useEffect(() => {
    //     return () => {

    //         dispatch({
    //           type: "TOGGLE_SINGLE",
    //           payload: { isSingle: false },
    //         });
    //     };
    //   }, []);

    const vote = (isLiked, articleId) => {
        if (!isLiked && state.isLogued && !votedAlready) {
            axios
                .post(`${state.apiBase}/like/${articleId}`, {type: true})
                .then(function (res) {
                    setError(null);
                    setVotedAlready(true);
                    setPopularity(popularity + 1);
                })
                .catch(function (error) {
                    setError("Se ha producido un error, por favor intente mas tarde.");
                });
        }
    };

    const clickBackdropHandler = (e) => {
        setOpenMailModal(false);
        setSent(false);
    };

    const clickSendHandler = (e) => {
        axios
            .post("/content/send-pdf", {
                id: single.id,
                type: state.apiBase.slice(1).slice(0, -1),
                email: e,
            })
            .then(function (res) {
                setError(null);
                setSent(true);
            })
            .catch(function (error) {
                setError(
                    "Se ha producido un error al enviar email, por favor revise e intente nuevamente."
                );
            });
    };

    if (error) {
        return (
            <div className="error text-center py-5 animated fadeIn">{error}</div>
        );
    }

    if (!single) {
        return (
            <div className="error text-center py-5 animated fadeIn">
                La publicación no existe.
            </div>
        );
    }
    return (
        <div className="single page">
            <EmailModal
                isOpen={openMailModal}
                clickBackdropHandler={clickBackdropHandler}
                clickSendHandler={clickSendHandler}
                sent={sent}
            />
            <div className="container">
                <div className="back-arrow animated fadeInDown">
                    <Link to={state.apiName}>
                        <svg viewBox="0 0 24 24">
                            <path
                                fill="#DF3838"
                                d="M21,11H6.83L10.41,7.41L9,6L3,12L9,18L10.41,16.58L6.83,13H21V11Z"
                            />
                        </svg>
                        Volver</Link>
                </div>
                <PageHeading/>
                <div className="row">
                    <div className="col-sm-12 col-lg-8">
                        <div className="content">
                            <div
                                className="title"
                                dangerouslySetInnerHTML={{__html: single.title}}
                            />
                            <div className="bydatelecture">
                                <span className="author">por {single.author}</span>
                                <img className="dot" src={Dot} alt="dot"/>
                                {single.created_at_readable}
                                <img className="dot" src={Dot} alt="dot"/>
                                {single.reading_time}
                            </div>
                            {video ? (
                                <iframe
                                    src={`${video}?title=0&portrait=0&byline=0&color=df3838`}
                                    frameBorder="0"
                                    width="100%"
                                    webkitallowfullscreen="true"
                                    mozallowfullscreen="true"
                                    allowFullScreen={true}
                                ></iframe>
                            ) : (
                                <img src={single.image} alt="image"/>
                            )}
                            <div className="desc">{single.description}</div>
                            <div
                                className="body"
                                dangerouslySetInnerHTML={{__html: single.body}}
                            />
                            {state.apiBase === "/clip" ||
                            state.apiBase === "/article" ||
                            state.apiBase === "/infography" ? (
                                <div
                                    className="popularity"
                                    onClick={() => vote(single.liked, single.id)}
                                >
                                    <img src={ClapGray} alt=""/>
                                    <img src={ClapRed} alt=""/>
                                    <span className="claps">{popularity}</span>
                                    {votedAlready || single.liked ? <span className="voted"> Ya votaste </span> : null}
                                </div>
                            ) : null}
                            <div className="buttons">
                                {pdf ? (
                                    <a className="link" href={pdf} target="_blank">Descargar PDF
                                    </a>
                                ) : null}
                                {state.apiName === "/herramientas/rutinas-de-ejercicios" ||
                                state.apiName === "/herramientas/planes-de-alimentacion" ? (
                                    <span
                                        className="link"
                                        onClick={(e) => setOpenMailModal(true)}
                                    >Enviar correo</span>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-1"></div>
                    <div className="col-sm-12 col-lg-3">
                        <SidebarFeatured/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-lg-8">
                        <RecentArticles/>
                    </div>
                    <div className="col-sm-12 col-lg-1"></div>
                    <div className="col-sm-12 col-lg-3">
                        {/* <SidebarCategories /> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(Single);
