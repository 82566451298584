import React, { useState, useEffect } from "react";
import Context from "../../context";
import { withRouter, Link, useLocation, useParams } from "react-router-dom";

import "./index.scss";
import arrowRed from "../../assets/img/icons/arrow-red.svg";
import arrowWhite from "../../assets/img/icons/arrow-white.svg";
import ClipIcon from "../../assets/img/icons/play-trans.svg";
// import Bg from "../../assets/img/bg.jpg";

function Video(props) {
  return (
    <div className="post-wide post-wide-video animated fadeInLeft">
      <div className="row">
        <Link
          to="#"
          onClick={(e) => props.clickModalHandler(e, props.id)}
          className="col-12 col-md-4"
        >
          <div className="img-cont">
            <img className="clip-icon" src={ClipIcon} alt="img icon" />
            <img src={props.image} alt="img featured" />
          </div>
        </Link>
        <div className="col-12 col-md-8 content">
          <Link to="#">
            <div className="title"> {props.title} </div>
          </Link>
          <div className="date"> {props.sharedBy} </div>
          <div className="excerpt"> {props.description} </div>
          <div className="content-footer" style={{ marginTop: "1rem" }}>
            <Link to="#" onClick={(e) => props.clickModalHandler(e, props.id)}>
              <span>Ver más</span>
              <img src={arrowRed} alt="arrow" />
              <img src={arrowWhite} alt="arrow" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Video);
