import React, { useState, useEffect } from "react";
import Context from "../../context";
import { withRouter, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import SidebarFeatured from "./../../components/Sidebar/Featured";
import PageHeading from "./../../components/PageHeading";
import PostsList from "../../components/PostList";
import RecentPosts from "../../components/RecentPosts";

// Icons
import Uroclips from "./../../assets/img/icons/heading/uroclips-icon.svg";
import Article from "./../../assets/img/icons/heading/article-icon.svg";
import Infography from "./../../assets/img/icons/heading/infographic-icon.svg";
import Rutin from "./../../assets/img/icons/heading/exercise-icon.png";
import Plan from "./../../assets/img/icons/heading/diet-icon.svg";
import Clinical from "./../../assets/img/icons/heading/clinical-cases-icon.svg";
import Calculator from "./../../assets/img/icons/heading/calculator-icon.svg";
import Edit from "./../../assets/img/icons/heading/edit-icon.svg";

import "./index.scss";

function Page(props) {
  const { state, dispatch } = React.useContext(Context);
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);

  let { pathname, key } = useLocation();
  const query = useParams().query;

  useEffect(() => {
    setError("cargando...");
    switch (pathname) {
      case "/uro-clips":
        axios
          .all([
            axios.get(`/clip?page=${currentPage}`),
            axios.get(`/tag/by-model/clip`),
          ])
          .then(
            axios.spread((...res) => {
              dispatch({
                type: "SET_PAGE_POSTS",
                payload: {
                  pagePosts: res[0].data.data,
                  pageCats: res[1].data,
                  pagination: res[0].data,
                  clipIcon: true,
                },
              });
              dispatch({
                type: "SET_PAGE_INFO",
                payload: {
                  apiBase: "/clip",
                  apiName: "/uro-clips",
                  apiUrl: `/clip?page=${currentPage}`,
                  catApiURL: "/tag/by-model/clip",
                  pageTitle: "Uro Clips",
                  pageIcon: Uroclips,
                },
              });
              setError(null);
            })
          )
          .catch((error) => {
            setError(
              "Se ha producido un error al obtener los posts, por favor intente mas tarde."
            );
          });
        break;
      case "/infografias":
        axios
          .all([
            axios.get(`/infography?page=${currentPage}`),
            axios.get(`/tag/by-model/infography`),
          ])
          .then(
            axios.spread((...res) => {
              dispatch({
                type: "SET_PAGE_POSTS",
                payload: {
                  pagePosts: res[0].data.data,
                  pageCats: res[1].data,
                  pagination: res[0].data,
                  clipIcon: false,
                },
              });
              dispatch({
                type: "SET_PAGE_INFO",
                payload: {
                  apiBase: "/infography",
                  apiName: "/infografias",
                  apiUrl: `/infography?page=${currentPage}`,
                  catApiURL: "/tag/by-model/infography",
                  pageTitle: "Infografías",
                  pageIcon: Infography,
                },
              });
              setError(null);
            })
          )
          .catch((error) => {
            setError(
              "Se ha producido un error al obtener los posts, por favor intente mas tarde."
            );
          });
        break;
      case "/articulos":
        axios
          .all([
            axios.get(`/article?page=${currentPage}`),
            axios.get(`/tag/by-model/article`),
          ])
          .then(
            axios.spread((...res) => {
              dispatch({
                type: "SET_PAGE_POSTS",
                payload: {
                  pagePosts: res[0].data.data,
                  pageCats: res[1].data,
                  pagination: res[0].data,
                  clipIcon: false,
                },
              });
              dispatch({
                type: "SET_PAGE_INFO",
                payload: {
                  apiBase: "/article",
                  apiName: "/articulos",
                  apiUrl: `/article?page=${currentPage}`,
                  pageTitle: "Artículos",
                  pageIcon: Article,
                },
              });
              setError(null);
            })
          )
          .catch((error) => {
            setError(
              "Se ha producido un error al obtener los posts, por favor intente mas tarde."
            );
          });
        break;
      case "/herramientas/rutinas-de-ejercicios":
        axios
          .get(`/routine?page=${currentPage}`)
          .then((res) => {
            dispatch({
              type: "SET_PAGE_POSTS",
              payload: {
                pagePosts: res.data.data,
                pagination: res.data,
                clipIcon: false,
              },
            });
            dispatch({
              type: "SET_PAGE_INFO",
              payload: {
                apiBase: "/routine",
                apiName: "/herramientas/rutinas-de-ejercicios",
                apiUrl: `/routine?page=${currentPage}`,
                pageTitle: "Rutinas de ejercicios",
                pageIcon: Rutin,
              },
            });
            setError(null);
          })
          .catch((error) => {
            setError(
              "Se ha producido un error al obtener los posts, por favor intente mas tarde."
            );
          });
        break;
      case "/herramientas/planes-de-alimentacion":
        axios
          // .get(`/content/routines-plans?page=${currentPage}`)
            .get(`/plan?page=${currentPage}`)
            .then((res) => {
            dispatch({
              type: "SET_PAGE_POSTS",
              payload: {
                pagePosts: res.data.data,
                pagination: res.data,
                clipIcon: false,
              },
            });
            dispatch({
              type: "SET_PAGE_INFO",
              payload: {
                apiBase: "/plan",
                apiName: "/herramientas/planes-de-alimentacion",
                apiUrl: `/plan?page=${currentPage}`,
                pageTitle: "Planes de alimentación",
                pageIcon: Plan,
              },
            });
            setError(null);
          })
          .catch((error) => {
            setError(
              "Se ha producido un error al obtener los posts, por favor intente mas tarde."
            );
          });
        break;
      case "/herramientas/calcular-psadt":
        dispatch({
          type: "SET_PAGE_POSTS",
          payload: {
            clipIcon: false,
          },
        });
        dispatch({
          type: "SET_PAGE_INFO",
          payload: {
            pageTitle: "Calcular PSADT",
            apiName: "/herramientas/calcular-psadt",
            pageIcon: Calculator,
            clipIcon: false,
          },
        });
        break;
      case "/search":
        axios
          .post(`/article/search?page=${currentPage}`, { query })
          .then(function (res) {
            dispatch({
              type: "SET_PAGE_POSTS",
              payload: {
                pagePosts: res.data.data,
                pagination: res.data,
                clipIcon: false,
              },
            });
            dispatch({
              type: "SET_PAGE_INFO",
              payload: {
                apiBase: "/article/search",
                apiName: "/articulos",
                apiUrl: `/article/search?page=${currentPage}`,
                pageTitle: "Buscar",
                pageIcon: Article,
              },
            });
            setError(null);
          })
          .catch(function (error) {
            setError("Se ha producido un error, por favor intente mas tarde.");
          });
        break;
      case "/casos-clinicos":
        dispatch({
          type: "SET_PAGE_POSTS",
          payload: {
            pageCats: [],
            pagination: [],
          },
        });
      default:
        axios
          .all([
            axios.get(`/article?page=${currentPage}`),
            axios.get(`/tag/by-model/article`),
          ])
          .then(
            axios.spread((...res) => {
              dispatch({
                type: "SET_PAGE_POSTS",
                payload: {
                  pagePosts: res[0].data.data,
                  pageCats: res[1].data,
                  pagination: res[0].data,
                  clipIcon: false,
                },
              });
              dispatch({
                type: "SET_PAGE_INFO",
                payload: {
                  apiBase: "/article",
                  apiName: "/articulos",
                  apiUrl: `/article?page=${currentPage}`,
                  pageTitle: "Artículos",
                  pageIcon: Article,
                },
              });
              setError(null);
            })
          )
          .catch((error) => {
            setError(
              "Se ha producido un error al obtener los posts, por favor intente mas tarde."
            );
          });
    }
  }, [pathname, currentPage]);

  // Pagination Effect
  useEffect(() => {
    setCurrentPage(1);
  }, [key, state.catApiURL]);

  // Categories filter Effect
  const filterHandler = (e, id) => {
    const filters = Array.from(
      document.querySelectorAll(".filters .filter")
    ).map((filter) => filter.classList.remove("active"));
    e.currentTarget.classList.add("active");

    if(id === -1){
        axios
          .all([
              axios.get(`${state.apiBase}?page=1`),
              axios.get(`/tag/by-model${state.apiBase}`),
          ])
          .then(
              axios.spread((...res) => {
                dispatch({
                  type: "SET_PAGE_POSTS",
                  payload: {
                    pagePosts: res[0].data.data,
                    pageCats: res[1].data,
                    pagination: res[0].data,
                  },
                });
                dispatch({
                  type: "SET_PAGE_INFO",
                  payload: {
                    apiName: pathname,
                    apiBase: state.apiBase,
                    apiUrl: `/${state.apiBase}?page=${currentPage}`,
                    pageTitle: state.pageTitle,
                    pageIcon: state.pageIcon,
                  },
                });
                setError(null);
              })
          )
          .catch((error) => {
            setError(
                "Se ha producido un error al obtener los posts, por favor intente mas tarde."
            );
          });
    }else{
        axios
          .all([
            axios.get(`${state.apiBase}/by-tag/${id}?page=${currentPage}`),
            axios.get(`/tag/by-model${state.apiBase}`),
          ])
          .then(
              axios.spread((...res) => {
                  console.log(res)
                dispatch({
                  type: "SET_PAGE_POSTS",
                  payload: {
                    pagePosts: res[0].data.data,
                    pageCats: res[1].data,
                    pagination: res[0].data,
                  },
                });
                dispatch({
                  type: "SET_PAGE_INFO",
                  payload: {
                    apiName: pathname,
                    apiBase: state.apiBase,
                    apiUrl: `/${state.apiBase}?page=${currentPage}`,
                    pageTitle: state.pageTitle,
                    pageIcon: state.pageIcon,
                  },
                });
                setError(null);
              })
          )
          .catch((error) => {
            setError(
                "Se ha producido un error al obtener los posts, por favor intente mas tarde."
            );
          });
    }


  };

  // Next Page
  const getNextPage = (e, page) => {
    Array.from(document.querySelectorAll(".article-wide.animated")).map((i) =>
      i.classList.replace("fadeInLeft", "fadeOutLeft")
    );
    setTimeout(() => {
      window.scrollTo(0, 0);
      setCurrentPage(page);
    }, 1000);
  };

  return (
    <div className="page">
      <div className="container">
        <PageHeading filterHandler={filterHandler} />
        <div className="row">
          <div className="col-sm-12 col-lg-8">
            <PostsList getNextPage={getNextPage} />
            <RecentPosts />
          </div>
          <div className="col-sm-12 col-lg-1"></div>
          <div className="col-sm-12 col-lg-3">
            <SidebarFeatured />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Page);
