import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import axios from "axios";

import ArticleWide from "../../components/PostWide";
import Pagination from "./../../components/Pagination";

import SidebarFeatured from "./../../components/Sidebar/Featured";
import PageHeading from "./../../components/PageHeading";

import "./index.scss";

function Search(props) {
  const [page, setPage] = useState([]);
  const [articles, setArticles] = useState([]);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const query = useParams().query;

  useEffect(() => {
    setError("cargando...");
    axios
      .post(`/article/search?page=${currentPage}`, { query })
      .then(function(res) {
        setArticles(res.data.data);
        delete res.data.data;
        setPage(res.data);
        setError(null);
      })
      .catch(function(error) {
        setError("Se ha producido un error, por favor intente mas tarde.");
      });
  }, [currentPage, query]);

  const getNextPage = (e, page) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  const result = !articles.length ? (
    <div className="error text-center py-5 animated fadeIn">
      No pudimos encontrar ningun artículo
    </div>
  ) : (
    <>
      <div className="section-title">Artículos recientes</div>
      <div className="recent-articles">
        {articles.map(art => (
          <ArticleWide {...art} key={art.id} />
        ))}
      </div>
      <div className="pagination">
        <Pagination data={page} getPage={getNextPage} />
      </div>
    </>
  );

  if (error) {
    return <div className="error text-center py-5">{error}</div>;
  }

  return (
    <div className="search page">
      <div className="container">
        <PageHeading />
        <div className="row">
          <div className="col-sm-12 col-lg-8">{result}</div>
          <div className="col-sm-12 col-lg-1"></div>
          <div className="col-sm-12 col-lg-3">
            <SidebarFeatured />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Search);
