import React from "react";
import {withRouter} from 'react-router-dom';
import PageHeading from './../../components/PageHeading';
import './index.scss';

function NotFound() {
  return (
    <div className="notfound page animated zoomInDown">
        <div className="container">
            <PageHeading />
            <div className="row">
            <div className="col-sm-12">
                <div className="code404 animated bounceInDown">404</div>
                <div className="ups animated bounceInUp">Ups! Algo salió mal.</div>
                <div className="desc animated bounceInUp">La página que usted está buscando no se encuentra en <br /> funcionamiento. Por favor vuelva a intentarlo mas tarde</div>
            </div>
            </div>
      </div>
    </div>
  );
}

export default withRouter(NotFound);