import React, { useState, useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";
import axios from "axios";
import Context from "./../../context";

import PostWide from "../PostWide";
import Pagination from "../Pagination";

import "./index.scss";

const PostsList = (props) => {
    const { state, dispatch } = React.useContext(Context);

  return state && state.pagePosts && state.pagePosts.length > 0 ? (
    <>
        <div className="section-title">{state.pageTitle} recientes</div>
        <div className="recent-posts">
        {state.pagePosts.map((art) => (
            <PostWide {...art} key={art.id} />
        ))}
        </div>
        <div className="pagination">
            <Pagination getNextPage={props.getNextPage} />
        </div>
    </>

  ) : (
    <div className="error text-center py-5 animated fadeIn">
        No se encontraron contenidos
    </div>
  );
};

export default withRouter(PostsList);
