import React from "react";
import { Route, Switch, BrowserRouter, useHistory } from "react-router-dom";
import Context from "./context";
import ScrollToTop from "./components/ScrollToTop";
import axios from "./Axios.config";

// Pages
import Page from "./pages/Page";
import Home from "./pages/Home";
import Single from "./pages/Single";
import Calculate from "./pages/Calculate";
// import ClinicalCases from "./pages/ClinicalCases/_index";
import ClinicalCases from "./pages/ClinicalCases/index";
import Videos from "./pages/Videos/index";
import Form from "./pages/Form";
import Search from "./pages/Search";
import NotFound from "./pages/NotFound";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";

// Components
import LoginModal from "./components/LoginModal";
import RegisterModal from "./components/RegisterModal";
import Header from "./components/Header";
import Footer from "./components/Footer";

const initialState = {
  isLogued: false,
  user: null,
  token: null,
  modalOpen: false,
  registerModalOpen: false,
  isSingle: false,
  pagePosts: [],
  pageCats: [],
  pageCatPosts: [],
  apiBase: "/article",
  apiName: "/articulos",
  apiUrl: "",
  catApiURL: "",
  pageTitle: "",
  pageIcon: "",
  clipIcon: false,
  pagination: [],
  clinicalCases: [],
  clinicalPagination: [],
  selectedCase: [],
  selectedCapsule: [],
  currentCapsuleQuestion: [],
};
const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("uroUser", JSON.stringify(action.payload.user));
      localStorage.setItem("uroToken", JSON.stringify(action.payload.token));
      return {
        ...state,
        isLogued: true,
        user: action.payload.user,
        token: action.payload.token,
      };
    case "LOGOUT":
      localStorage.removeItem("uroUser");
      localStorage.removeItem("uroToken");
      return {
        ...state,
        isLogued: false,
        user: null,
        modalOpen: false,
      };
    case "TOGGLE_MODAL":
      return {
        ...state,
        modalOpen: action.payload.modalOpen,
      };
    case "TOGGLE_REGISTER_MODAL":
      return {
        ...state,
        registerModalOpen: action.payload.registerModalOpen,
      };
    case "TOGGLE_SINGLE":
    //   localStorage.removeItem("uroUser");
    //   localStorage.removeItem("uroToken");
      return {
        ...state,
        isSingle: action.payload.isSingle,
      };
    case "SET_PAGE_INFO":
      return {
        ...state,
        apiBase: action.payload.apiBase,
        apiUrl: action.payload.apiUrl,
        apiName: action.payload.apiName,
        catApiURL: action.payload.catApiURL,
        pageTitle: action.payload.pageTitle,
        pageIcon: action.payload.pageIcon,
      };
    case "SET_PAGE_POSTS":
      return {
        ...state,
        pagePosts: action.payload.pagePosts,
        pageCatPosts: action.payload.pageCatPosts,
        pageCats: action.payload.pageCats,
        pagination: action.payload.pagination,
        clipIcon: action.payload.pagination,
      };
    case "SET_CAPSULES_INFO":
      return {
        ...state,
        clinicalCases: action.payload.clinicalCases,
        clinicalPagination: action.payload.clinicalPagination,
        selectedCase: action.payload.selectedCase,
        selectedCapsule: action.payload.selectedCapsule,
        currentCapsuleQuestion: action.payload.currentCapsuleQuestion,
      };
    default:
      return state;
  }
};

function App() {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  if (!localStorage.getItem("uroUser")) {
    localStorage.setItem("uroUser", JSON.stringify({}));
  } else if (
    JSON.parse(localStorage.getItem("uroUser")).token ===
    JSON.parse(localStorage.getItem("uroToken"))
  ) {
    initialState.user = JSON.parse(localStorage.getItem("uroUser"));
    initialState.isLogued = true;
  }

  return (
    <BrowserRouter>
      <Context.Provider value={{ state, dispatch }}>
        <ScrollToTop>
          <LoginModal isOpen={state.modalOpen} />
          <RegisterModal isOpen={state.registerModalOpen} />
          <Header />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route
              exact
              path={[
                "/uro-clips",
                "/infografias",
                "/articulos",
                "/herramientas/rutinas-de-ejercicios",
                "/herramientas/planes-de-alimentacion",
              ]}
              component={Page}
            />
            <Route
              exact
              path={[
                "/uro-clips/:slug",
                "/infografias/:slug",
                "/articulos/:slug",
                "/herramientas/rutinas-de-ejercicios/:slug",
                "/herramientas/planes-de-alimentacion/:slug",
              ]}
              component={Single}
            />

            <Route
              exact
              path="/herramientas/calcular-psadt"
              component={Calculate}
            />
            <Route exact path="/casos-clinicos" component={ClinicalCases} />
            {/*<Route exact path="/videos" component={Videos} />*/}
            <Route exact path="/search/:query" component={Search} />
            <Route exact path="/form/login" component={Form} />
            <Route exact path="/form/registro" component={Form} />
            <Route exact path="/terminos-y-condiciones" component={Terms} />
            {/* <Route exact path="/politica-y-privacidad" component={Privacy} /> */}
            <Route component={NotFound} />
          </Switch>
          <Footer />
        </ScrollToTop>
      </Context.Provider>
    </BrowserRouter>
  );
}

export default App;
