import React, {useState, useEffect} from "react";
import {Link, useLocation, useHistory} from "react-router-dom";
import Context from "../../context";
import Logo from "./../../assets/img/logo.svg";
import LupaIcon from "./../../assets/img/icons/lupa.svg";
import UserIcon from "./../../assets/img/icons/user.svg";
import MenuIcon from "./../../assets/img/icons/menu.svg";

import "./index.scss";

function Header(props) {
    const {state, dispatch} = React.useContext(Context);

    const location = useLocation();
    const [isOpenTools, setIsOpenTools] = useState(false);
    const [isOpenUser, setIsOpenUser] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [isOpenNav, setIsOpenNav] = useState(false);

    const toggleTools = () => (closeAll(), setIsOpenNav(true), setIsOpenTools(true), setIsOpenTools(!isOpenTools));
    const toggleUser = () => (closeAll(), setIsOpenNav(true), setIsOpenUser(!isOpenUser));
    const toggleNav = () => (closeAll(), setIsOpenNav(!isOpenNav));
    const toggleSearch = () => (closeAll(), setIsOpenNav(true), setIsSearch(!isSearch));

    let history = useHistory();

    const loginModalHandler = (e, to) => {
        if (!state.isLogued) {
            e.preventDefault();
            dispatch({
                type: "TOGGLE_MODAL",
                payload: {modalOpen: true}
            });
        } else {
            dispatch({
                type: "TOGGLE_MODAL",
                payload: {modalOpen: false}
            });
        }
        closeAll();
    };

    const backToList = (e, to) => {
        if (window.location.pathname === '/casos-clinicos') {
            window.location.reload(false);
        }
    };


    const fixMenu = () => {
        if (document.querySelector("header")) {
            if (window.pageYOffset >= 120) {
                document.querySelector("header").classList.add("sticky-top");
            } else {
                document.querySelector("header").classList.remove("sticky-top");
            }
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", fixMenu);
        closeAll();

    }, [location]);

    const closeAll = () => {
        setIsOpenTools(false);
        setIsOpenUser(false);
        setIsOpenNav(false);
        setIsSearch(false);
    };

    const doLogout = e => {
        e.preventDefault();
        closeAll();
        dispatch({
            type: "LOGOUT"
        });
        history.push("/");
    };

    const handleKeyPress = event => {
        if (event.key === "Enter") {
            history.push(`/search/${event.currentTarget.value}`);
        }
    };

    const indexMenuColor = {
        backgroundColor: location.pathname !== "/" ? "#DF3838" : "transparent"
    };
    const indexDropDownColor = {
        backgroundColor:
            location.pathname !== "/"
                ? "rgba(223, 56, 56, 0.88)"
                : "rgba(5, 29, 67, 0.88)"
    };
    const indexDropDownBorderColor = {
        borderColor:
            location.pathname !== "/"
                ? "rgba(223, 56, 56, 0.88)"
                : "rgba(5, 29, 67, 0.88)"
    };

    const userStatus = state.isLogued ? (
        <>
      <span
          className="nav-link dropdown-toggle user-toggler"
          href="#"
          onClick={e => toggleUser()}
      >
        {state.user.name} <br/> {state.user.job}
      </span>
            <div
                className={
                    isOpenUser
                        ? "dropdown-menu logout d-block"
                        : "dropdown-menu logout d-none"
                }
                style={indexDropDownBorderColor}
            >
                <Link
                    className="dropdown-item"
                    to="/"
                    onClick={e => doLogout(e)}
                    style={indexDropDownColor}
                >
                    Cerrar Sesión
                </Link>
            </div>
        </>
    ) : (
        <>
            <Link to="/form/login">
                <img src={UserIcon} alt="icon"/>
            </Link>
        </>
    );

    return (
        <header style={indexMenuColor}>
            <div
                className={
                    isOpenTools || isOpenUser ? "backdrop d-block" : "backdrop d-none"
                }
                onClick={e => closeAll()}
            ></div>
            <div className="container">
                <nav className="navbar navbar-expand-lg">
                    <Link className="navbar-brand" to="/">
                        <img src={Logo} alt="logo"/>
                    </Link>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        onClick={e => toggleNav()}
                    >
            <span className="navbar-toggler-icon">
              <img src={MenuIcon} alt=""/>
            </span>
                    </button>

                    <div
                        className={
                            isOpenNav
                                ? "collapse navbar-collapse d-flex"
                                : "collapse navbar-collapse d-none"
                        }
                    >
                        <ul className="navbar-nav">
                            <li className="nav-item active">
                                <Link className="nav-link" to="/uro-clips">
                                    Uro clips
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/infografias">
                                    Infografías
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/articulos">
                                    Artículos
                                </Link>
                            </li>
                            <li className="nav-item dropdown">
                <span
                    className="nav-link dropdown-toggle"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    onClick={e => toggleTools()}
                >
                  Herramientas para la consulta
                </span>
                                <div
                                    className={
                                        isOpenTools
                                            ? "dropdown-menu d-block"
                                            : "dropdown-menu d-none"
                                    }
                                    aria-labelledby="navbarDropdown"
                                    style={indexDropDownBorderColor}
                                >
                                    {/* <Link
                    className="dropdown-item"
                    style={indexDropDownColor}
                    to="/herramientas/rutinas-y-planes"
                    onClick={e => loginModalHandler(e, "/herramientas/rutinas-y-planes")}
                  >
                    Rutinas de ejercicios y <br /> Planes de alimentación
                  </Link> */}
                                    <Link
                                        className="dropdown-item"
                                        style={indexDropDownColor}
                                        to="/herramientas/rutinas-de-ejercicios"
                                        onClick={e => loginModalHandler(e, "/herramientas/rutinas-de-ejercicios")}
                                    >
                                        Rutinas de ejercicios
                                    </Link>
                                    <Link
                                        className="dropdown-item"
                                        style={indexDropDownColor}
                                        to="/herramientas/planes-de-alimentacion"
                                        onClick={e => loginModalHandler(e, "/herramientas/planes-de-alimentacion")}
                                    >
                                        Planes de alimentación
                                    </Link>
                                    <Link
                                        className="dropdown-item"
                                        to="/herramientas/calcular-psadt"
                                        onClick={(e) => {loginModalHandler(e, "/herramientas/calcular-psadt"); toggleTools()}}
                                        style={indexDropDownColor}
                                    >
                                        Calculadora PSADT
                                    </Link>
                                </div>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className="nav-link"
                                    to="/casos-clinicos"
                                    onClick={e => loginModalHandler(e, "/casos-clinicos") & backToList(e, "/casos-clinicos")}
                                >
                                    Casos clínicos
                                </Link>
                            </li>
                            {/*<li className="nav-item">*/}
                            {/*    <Link*/}
                            {/*        className="nav-link"*/}
                            {/*        to="/videos"*/}
                            {/*        onClick={e => loginModalHandler(e, "/videos")}*/}
                            {/*    >*/}
                            {/*        Videos*/}
                            {/*    </Link>*/}
                            {/*</li>*/}
                        </ul>

                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item active">
                                <div className="search">
                                    <input
                                        className={
                                            isSearch
                                                ? "searching form-control mr-sm-2"
                                                : "form-control mr-sm-2"
                                        }
                                        type="search"
                                        placeholder="Buscar"
                                        onKeyPress={handleKeyPress}
                                    />
                                    <img
                                        src={LupaIcon}
                                        alt="Lupa"
                                        className="my-2 my-sm-0"
                                        onClick={() => toggleSearch()}
                                    />
                                </div>
                            </li>
                            <li className="nav-item dropdown">{userStatus}</li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
    );
}

export default Header;
