import React from "react";
import { Link } from "react-router-dom";
import Context from "./../../context";

import Bground from "./../../assets/img/bg.jpg";
import Calculator from "./../../assets/img/home/calculator.svg";
import Clips from "./../../assets/img/home/clips.svg";
import Infographic from "./../../assets/img/home/infographic.svg";
import Tools from "./../../assets/img/home/tools.svg";
import Articles from "./../../assets/img/home/articles.svg";

import "./index.scss";

function Hero(props) {
  const {state} = React.useContext(Context);
  const content = state.isLogued ? (
    <div className="container container-logued">
      <div className="d-flex justify-content-start flex-wrap row">
        <Link to="/herramientas/calcular-psadt" className="mx-auto col-md-4 d-flex flex-column animated zoomInLeft">
            <img src={Calculator} alt="img" />
            <div className="title">Calculadora PSADT</div>
            <div className="text"> Obtenga el tiempo de duplicación del Antígeno Prostático Específico. </div>
        </Link>
        <Link to="/uro-clips" className="mx-auto col-md-4 d-flex flex-column animated zoomInUp">
            <img src={Clips} alt="img" />
            <div className="title">Uro Clips</div>
            <div className="text"> A través de videos cortos consulte los principales temas de interés en Urología. </div>
        </Link>
        <Link to="/infografias" className="mx-auto col-md-4 d-flex flex-column animated zoomInDown">
            <img src={Infographic} alt="img" />
            <div className="title">Infografías</div>
            <div className="text"> Visualice de manera práctica contenidos de especialidad. </div>
        </Link>
        <Link to="/herramientas/planes-de-alimentacion" className="mx-auto col-md-4 d-flex flex-column animated zoomInUp">
            <img src={Tools} alt="img" />
            <div className="title">Herramientas para la consulta</div>
            <div className="text"> Consulte planes alimenticios y rutinas de ejercicios para compartir con sus pacientes. </div>
        </Link>
        <Link to="/articulos" className="mx-auto col-md-4 d-flex flex-column animated zoomInRight">
            <img src={Articles} alt="img" />
            <div className="title">Artículos</div>
            <div className="text"> Acceso a artículos basados en los más recientes estudios sobre temas urológicos. </div>
        </Link>
      </div>
    </div>
  ) : (
    <div className="container container-logout">
      <div className="title animated fadeIn">Uro Conexión</div>
      <div className="subtitle animated fadeIn">
        Una nueva forma de conectar a través del conocimiento
      </div>
      <div className="btn-cont animated fadeIn">
        <Link to="/form/registro" className="btn btn-primary btn-lg">
          Registro
        </Link>
        <Link to="/form/login" className="btn btn-secondary btn-lg outline">
          Login
        </Link>
      </div>
    </div>
  );

  return (
    <div
      className="hero"
      style={{ background: `url(${Bground}) no-repeat center/cover` }}
    >
      {content}
    </div>
  );
}

export default Hero;
