import React from "react";
import ArrowIcon from "./../../assets/img/icons/arrow-blue.svg";
import "./index.scss";
import Context from "./../../context";

const Paginate = props => {
    const {state, dispatch} = React.useContext(Context);
    let lis = []; // <li> 's
    if (state.pagination && state.pagination.last_page) {
        for (let i = 1; i <= state.pagination.last_page; i++) {
            lis.push(<li className={state.pagination.current_page === i ? 'page-item active' : 'page-item'} key={i}
                         onClick={(e) => props.getNextPage(e, i)}>
                <span className="page-link">{i}</span>
            </li>)
        }
        return (
            <ul className="pagination">
                <li className={state.pagination.prev_page_url ? 'page-item' : 'page-item disabled'}
                    onClick={(e) => props.getNextPage(e, 1)}>
                <span className="page-link">
                    <img src={ArrowIcon} className="arrow-left" alt="icon"/>
                </span>
                </li>
                {lis.map(i => i)}
                <li className={state.pagination.next_page_url ? 'page-item' : 'page-item disabled'}
                    onClick={(e) => props.getNextPage(e, state.pagination.last_page)}>
                <span className="page-link">
                    <img src={ArrowIcon} alt="icon"/>
                </span>
                </li>
            </ul>
        )
    } else {
        return null
    }

};
export default Paginate;

