import React, {useState, useEffect} from "react";
import Context from "../../context";
import {useLocation, withRouter, useParams, Link} from "react-router-dom";
import axios from "axios";
import PageHeading from "./../../components/PageHeading";
import RecentPosts from "../../components/RecentPosts";
import Pagination from "../../components/Pagination";

import Questions from "./questions";
import Results from "./results";

import Clinical from "./../../assets/img/icons/heading/clinical-cases-icon.svg";
import Checkon from "./../../assets/img/icons/check-on.svg";
import Checkoff from "./../../assets/img/icons/check-off.svg";

import "./index.scss";

function ClinicalCases(props) {
    const {state, dispatch} = React.useContext(Context);
    const [error, setError] = useState(null);
    const [stage, setStage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [showQuestions, setShowQuestions] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [checkedIcon, setCheckedIcon] = useState(0);
    const [showResults, setShowResults] = useState(false);
    const [answers, setAnswers] = useState([]);
    const [totalQuestions, setTotalQuestions] = useState(0);

    let {pathname, key} = useLocation();


    const hotFixPaginate = (page) => {
        axios.get(`/challenge?page=${page}`).then(res => {
            dispatch({
                type: "SET_PAGE_INFO",
                payload: {
                    pageTitle: 'Casos Clínicos',
                    pageIcon: Clinical
                }
            });
            dispatch({
                type: "SET_CAPSULES_INFO",
                payload: {
                    clinicalCases: res.data.data,
                    clinicalPagination: res.data,
                }
            });
            dispatch({
                type: "SET_PAGE_POSTS",
                payload: {
                    pagination: res.data
                }
            });
            setError(null);
        }).catch((error) => {
            setError("Se ha producido un error al obtener los casos clínicos, por favor intente mas tarde.");
        });
    };

    useEffect(() => {
        axios.get(`/challenge?page=${currentPage}`).then(res => {
            dispatch({
                type: "SET_PAGE_INFO",
                payload: {
                    pageTitle: 'Casos Clínicos',
                    pageIcon: Clinical
                }
            });
            dispatch({
                type: "SET_CAPSULES_INFO",
                payload: {
                    clinicalCases: res.data.data,
                    clinicalPagination: res.data,
                }
            });
            dispatch({
                type: "SET_PAGE_POSTS",
                payload: {
                    pagination: res.data
                }
            });
            setError(null);
        }).catch((error) => {
            setError("Se ha producido un error al obtener los casos clínicos, por favor intente mas tarde.");
        });
    }, []);

    useEffect(() => {
        setCurrentPage(1);
    }, [key, pathname]);

    // Next Page
    const getNextPage = (e, page) => {
        Array.from(document.querySelectorAll(".clinical-stage.animated")).map((i) =>
            i.classList.replace("fadeInLeft", "fadeOutLeft")
        );
        setTimeout(() => {
            window.scrollTo(0, 0);
            setCurrentPage(page);
            hotFixPaginate(page);
        }, 1000);
    };

    const stage1SelectedCase = (e, caseId, capsuleId) => {
        axios.get(`/challenge/${caseId}`).then(res => {
            const capsule = res.data.capsules.filter(i => i.id === capsuleId)[0];

            let auxCompleteObject = res.data;

            axios.get(`/tag/${res.data.tag_id}`).then(ress => {
                auxCompleteObject.tag_name = ress.data.title;
                dispatch({
                    type: "SET_CAPSULES_INFO",
                    payload: {
                        selectedCase: auxCompleteObject,
                        selectedCapsule: capsule,
                    }
                });
                setQuestions(capsule.questions);
                setTotalQuestions(capsule.questions.length);
                setStage(2);
                setError(null);
            }).catch((errorr) => {
                setError("Se ha producido un error al obtener los posts, por favor intente mas tarde.");
            });


        }).catch((error) => {
            setError("Se ha producido un error al obtener los posts, por favor intente mas tarde.");
        });

    };

    // Set Current Cuestion
    const nextQuestion = (e, q) => {
        if (currentQuestion + 1 < totalQuestions) {
            setCurrentQuestion(currentQuestion + 1);
            setCheckedIcon(currentQuestion + 1);
        }
        if (currentQuestion + 1 === totalQuestions) {
            setShowResults(true);
            setCheckedIcon(currentQuestion);
            setStage(3);
        }
    };
    const beforeQuestion = (e, q) => {
        if (currentQuestion > 1) {
            setCurrentQuestion(currentQuestion - 1);
            setCheckedIcon(currentQuestion - 1);
        }
        if (currentQuestion == 0) {
            setCheckedIcon(0);
            setStage(1);
        }
    };

    // Set Answers
    const userAnswers = (e, q, a) => {
        Array.from(document.querySelectorAll('.questions .question-active .answer')).map(r => r.classList.add('disabled'));
        e.currentTarget.classList.remove('disabled');
        e.currentTarget.classList.add('active');
        setAnswers([...answers, {q, a}]);
    };

    const resetChallenge = () => {
        setTimeout(() => {
            window.scrollTo(0, 0);
            window.location.reload(true);
        }, 1000);
    };

    const resetStageState = () => {
        setStage(1)
        axios.get(`/challenge?page=${currentPage}`).then(res => {
            dispatch({
                type: "SET_CAPSULES_INFO",
                payload: {
                    clinicalCases: res.data.data,
                    clinicalPagination: res.data
                }
            });
            dispatch({
                type: "SET_PAGE_POSTS",
                payload: {
                    pagination: res.data
                }
            });
            setError(null);
        }).catch((error) => {
            setError("Se ha producido un error al obtener los casos clínicos, por favor intente mas tarde.");
        });
    }

    return (
        <div className="page clinical-cases">
            <div className="container">
                <div className="back-arrow animated fadeInDown">
                    {stage === 2 ?
                        <>

                            <span className="backLink" onClick={resetStageState}>
                                <svg viewBox="0 0 24 24">
                                <path
                                    fill="#DF3838"
                                    d="M21,11H6.83L10.41,7.41L9,6L3,12L9,18L10.41,16.58L6.83,13H21V11Z"
                                />
                            </svg>
                                Volver</span>
                        </> : null
                    }
                </div>
                <PageHeading/>
                <div className="row">
                    <div className="col-sm-12 col-lg-8">
                        {stage === 1 ?
                            <div className="clinical-stage-1 animated fadeInLeft">
                                {/*<h1> Ponga a prueba su conocimiento a través las siguientes video cápsulas*/}
                                {/*    interactivas. </h1>*/}
                                <h2> Elija el caso clínico de su preferencia </h2>
                                {state.clinicalCases
                                    ? Array.from(state.clinicalCases).map((cCase, key) => (
                                        <div key={key}>
                                            {Array.from(cCase.capsules).map((capsule, k) => (
                                                <div className="topic-case" key={k}
                                                     onClick={(e) => stage1SelectedCase(e, cCase.id, capsule.id)}>
                                                    {/*<strong className="cases">Caso clínico routines-plans?</strong> /*/}
                                                    <strong className="cases">Caso clínico </strong> /
                                                    {/*<span className="capsules">Cápsula {cCase.title}</span>*/}
                                                    <span className="capsules"> {cCase.title}</span>
                                                </div>
                                            ))}
                                        </div>
                                    ))
                                    : null
                                }
                                <div className="pagination">
                                    {/*{console.log(state)}*/}
                                    <Pagination getNextPage={getNextPage}/>
                                </div>
                            </div>
                            : null}


                        {stage === 2 ?
                            <div className="clinical-stage-2 animated fadeInLeft">
                                {/*<h1> Tema {state.selectedCase.id} / Cápsula {state.selectedCapsule.id} </h1>*/}
                                <h1> {state.selectedCase.tag_name} / {state.selectedCase.title}</h1>
                                <iframe
                                    src={`${state.selectedCapsule.vimeo}?title=0&portrait=0&byline=0&color=df3838`}
                                    frameBorder="0"
                                    width="100%"
                                    webkitallowfullscreen="true"
                                    mozallowfullscreen="true"
                                    allowFullScreen={true}
                                ></iframe>
                                {/*<h2> Responder preguntas acerca de </h2>*/}
                                {/*{!showQuestions ? <button className="clinical-btn-white begin"*/}
                                {/*                          onClick={(e) => setShowQuestions(true)}> Responder*/}
                                {/*    preguntas </button> : null}*/}
                                {/*{showQuestions ?*/}
                                {/*    <Questions*/}
                                {/*        questions={questions}*/}
                                {/*        currentQuestion={currentQuestion}*/}
                                {/*        totalQuestions={totalQuestions}*/}
                                {/*        beforeQuestion={beforeQuestion}*/}
                                {/*        nextQuestion={nextQuestion}*/}
                                {/*        userAnswers={userAnswers}*/}
                                {/*    />*/}
                                {/*    : null}*/}
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                            </div>
                            : null}

                        {stage === 3 ?
                            <div className="clinical-stage-3 animated fadeInleft">
                                {showResults ?
                                    <Results
                                        questions={questions}
                                        userAnswers={answers}
                                        reset={resetChallenge}
                                    />
                                    : null}
                            </div>
                            : null}

                    </div>
                    <div className="col-sm-12 col-lg-1"></div>
                    <div className="col-sm-12 col-lg-3">
                        {/* <SidebarFeatured /> */}
                        {stage === 2 ?
                            <div className="sidebar">
                                {/*<div>*/}
                                {/*    {[...Array(totalQuestions)].map((i, k) => <img*/}
                                {/*        src={k < checkedIcon ? Checkon : Checkoff} alt="icon" key={k}/>)}*/}
                                {/*</div>*/}
                                {/*<strong>{currentQuestion}</strong>/{totalQuestions} <br/> Preguntas respondidas*/}
                            </div>
                            : null}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(ClinicalCases);
