import React, {useEffect} from "react";
import { Link, withRouter, useParams, useLocation } from "react-router-dom";
import Context from "./../../context";
import "./index.scss";

function LoginModal(props) {
  const { state, dispatch } = React.useContext(Context);
  
  let location = useLocation();
  if (state.modalOpen) {
      if (document.querySelector(".page")) {
          document.querySelector(".page").style.position = "fixed";
          document.querySelector(".page").style.width = "100%";
      }
  }
  const onClickHandler = () => {
    if (state.modalOpen) {
        if (document.querySelector(".page")) {
            document.querySelector(".page").style.position = "relative";
        }
    }
    // if (state.isSingle && !state.isLogued) {
    //     dispatch({
    //         type: "TOGGLE_SINGLE",
    //         payload: { isSingle: true }
    //     });
    // } else {
    //     dispatch({
    //         type: "TOGGLE_MODAL",
    //         payload: { modalOpen: false }
    //     });
    // }
    if(!state.isSingle) {
        dispatch({
            type: "TOGGLE_MODAL",
            payload: { modalOpen: false }
        });
    } else {
        dispatch({
            type: "TOGGLE_MODAL",
            payload: { modalOpen: true }
        });
    }
  };

    useEffect(() => {
        dispatch({
            type: "TOGGLE_MODAL",
            payload: { modalOpen: false }
        });
    }, [location]);

  return (
    <div
      className="login-modal"
      onClick={onClickHandler}
      style={{ display: state.modalOpen ? "flex" : "none" }}
    >
      <div className="container">
        <div className="title">
            Para poder acceder a todo el contenido de Uro Conexión por favor inicie sesión o regístrese
        </div>
        {/* <div className="subtitle">
          Lorem Ipsum es simplemente el texto de relleno de las imprentas y
          archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de
          las industrias .
        </div> */}
        <div className="btn-cont">
          <Link to="/form/registro" className="btn btn-primary btn-lg">
            Registro
          </Link>
          <Link to="/form/login" className="btn btn-secondary btn-lg outline">
            Login
          </Link>
        </div>
      </div>
    </div>
  );
}

export default LoginModal;
