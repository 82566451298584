import React from "react";
import { Link, withRouter, useLocation } from "react-router-dom";
import Context from "../../context";
import "./index.scss";

import Calculadora from "./../../assets/img/icons/calculadora.svg";

function PageHeading(props) {
    const { state, dispatch } = React.useContext(Context);

    const loginModalHandler = (e, to) => {
        if (!state.isLogued) {
            e.preventDefault();
            dispatch({
                type: "TOGGLE_MODAL",
                payload: {modalOpen: true}
            });
        } else {
            dispatch({
                type: "TOGGLE_MODAL",
                payload: {modalOpen: false}
            });
        }
    };

  return (
    <div className="page-heading">
      <div className="row">
        <div className="col-sm-12 col-lg-8 pagecrumb animated fadeInLeft">
            { state.pageTitle && state.pageIcon ?
            <div>
                <img src={state.pageIcon} alt="icono" />
                <span> {state.pageTitle} </span>
            </div>
            : null }
        </div>
        <div className="col-sm-12 col-lg-4 btn-tools animated fadeInDown">
          <Link className="tool-calc" to="/herramientas/calcular-psadt"  onClick={e => loginModalHandler(e, "/herramientas/rutinas-de-ejercicios")}>
            calcular PSADT <img src={Calculadora} alt="icon" />
          </Link>
        </div>
      </div>
      {
          state.pageCats ?
            <div className="row">
                <div className="col-sm-12 col-lg-8 filters animated fadeInDown">
                    {/*{state.pageCats.push(state.pageCats[0])}*/}
                    { state.pageCats.length > 0 ? <span className='filter active' key='-1' onClick={(e) => props.filterHandler(e, -1)}>TODOS</span> : null }
                    {
                        state.pageCats
                        &&
                        state.pageCats.map((cat, key) =>
                        <span className={`filter ${props.activeCategory === cat.id ? 'active' : ''}`} key={key} onClick={(e) => props.filterHandler(e, cat.id)}> {cat.title} </span>)
                    }
                </div>
            </div>
            : null
      }
    </div>
  );
}

export default withRouter(PageHeading);
