import axios from "axios";

axios.defaults.baseURL =
  // "http://dashboard.uroconexion.php71.projectsunderdev.com/api"; // Servidor de staging local
  "https://dashboard.uroconexion.com.mx/api"; // Producción

axios.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const token = JSON.parse(localStorage.getItem("uroToken"));

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export default axios;
