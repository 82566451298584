import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import PostCard from "../PostCard";
import PlayIcon from "../../assets/img/icons/play-trans.svg";
import Context from "./../../context";
import './index.scss';

const RecentPosts = props => {
    const { state, dispatch } = React.useContext(Context);
    const [posts, setPosts] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        setError("cargando...");
        axios
        .get("/article/recent")
        .then(function(res) {
            setPosts(res.data);
            setError(null);
        })
        .catch(function(error) {
            setError("Se ha producido un error, por favor intente mas tarde.");
        });
    }, []);

  if (error) {
    return <div className="error text-center py-5">{error}</div>;
  }

  return !posts.length ? (
    <div className="error text-center py-5 animated fadeIn">No se encontraron artículos recientes</div>
  ) : (
    <>
      <div className="section-title">Artículos recientes</div>
      <div className="recent-posts">
        {posts.map(post => (
          <PostCard {...post} key={post.id} />
        ))}
      </div>
    </>
  );
};

export default withRouter(RecentPosts);
