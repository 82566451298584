import React, {useEffect} from "react";
import { Link, withRouter, useParams, useLocation, useHistory } from "react-router-dom";
import Context from "./../../context";
import "./index.scss";

function RegisterModal(props) {
  const { state, dispatch } = React.useContext(Context);
  let location = useLocation();
  let history = useHistory();
  if (state.registerModalOpen) {
      if (document.querySelector(".page")) {
          document.querySelector(".page").style.position = "fixed";
          document.querySelector(".page").style.width = "100%";
      }
  }
  const onClickHandler = () => {
    if (state.registerModalOpen) {
        if (document.querySelector(".page")) {
            document.querySelector(".page").style.position = "relative";
        }
        dispatch({
            type: "TOGGLE_REGISTER_MODAL",
            payload: { registerModalOpen: false }
        });
    }
    // else {
    //     dispatch({
    //         type: "TOGGLE_REGISTER_MODAL",
    //         payload: { registerModalOpen: false }
    //     });
    // }
  };

    useEffect(() => {
        dispatch({
            type: "TOGGLE_REGISTER_MODAL",
            payload: { registerModalOpen: false }
        });
    // }, [location]);
    }, []);

  return (
    <div
      className="register-modal"
      onClick={onClickHandler}
      style={{ display: state.registerModalOpen ? "flex" : "none" }}
    >
      <div className="container">
          <div className="dismiss" onClick={onClickHandler}>X</div>
        <div className="title">
          Registro creado exitosamente.
        </div>
      </div>
    </div>
  );
}

export default RegisterModal;
