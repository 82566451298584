import React from 'react';

function Results (props) {
    const {questions, userAnswers} = props;
    
    let answerClarification = "";

    const compare = (key, question, answer, isRight) => {
        const style = userAnswers
                ? isRight
                    ? props.userAnswers[key].a === answer ? "answer correct correct-active" : "answer correct"
                    : props.userAnswers[key].a === answer ? "answer active" : "answer"
                : "answer";

        if(style.includes("correct-active")) {
            answerClarification = "Respuesta correcta (Respuesta elegida)";
            return style
        }
        else if(style.includes("correct")) {
            answerClarification = "Respuesta correcta";
            return style
        }
        else if(style.includes("active")) {
            answerClarification = "Respuesta elegida";
            return style
        }
        else {
            answerClarification = "";
            return style
        }
        
    }
    
    return (
        <div className="results">
            {questions ? 
                questions.map((question, k) => (
                    <div key={k} className="question-active">
                        <div className="question"> {question.wording } </div>
                        <div>
                            {question.answers.map((answer, key) => (
                                <div className={ compare(k, answer.question_id, answer.id, answer.is_right) } key={key}> 
                                    <span className="circle"></span> 
                                    <span className="text">{ answer.text } </span>
                                    <span className="clarification"> {answerClarification} </span>
                                    {/* {answer.is_right ? ( 
                                         <div
                                            className="tip"
                                            data-toggle="tooltip"
                                            data-placement="left"
                                            title={`El ${answer.average}% de las personas acierta esta pregunta`}
                                        >
                                            !
                                        </div>
                                    ) : null} */}
                                </div>
                            ))}
                        </div>
                    </div>
                ))
            : null}
            <div className="results-btns">
                <button className="clinical-btn-white end" onClick={e => props.reset(e)}> Comenzar otro reto </button>
            </div>
        </div>
    )

}

export default Results;