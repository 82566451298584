import React, { useState } from "react";
import Context from "./../../context";
import { Link } from "react-router-dom";
import "./index.scss";

const Footer = () => {
  const { state, dispatch } = React.useContext(Context);
  const loginModalHandler = (e, to) => {
    if (!state.isLogued) {
      e.preventDefault();
      dispatch({
        type: "TOGGLE_MODAL",
        payload: { modalOpen: true }
      });
    } else {
      dispatch({
        type: "TOGGLE_MODAL",
        payload: { modalOpen: false }
      });
    }
  };
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="first-row col-sm-12">
            <Link className="footer-menu-item" to="/uro-clips">
              Uro clips
            </Link>
            <Link className="footer-menu-item" to="/infografias">
              Infografías
            </Link>
            <Link className="footer-menu-item" to="/articulos">
              Artículos
            </Link>
            {/* <Link className="footer-menu-item" to="/herramientas/rutinas-y-planes">
                Rutinas de ejercicios y Planes de alimentación
            </Link> */}
            <Link className="footer-menu-item" to="/herramientas/rutinas-de-ejercicios">
                Rutinas de ejercicios
            </Link>
            <Link className="footer-menu-item" to="/herramientas/planes-de-alimentacion">
                Planes de alimentación
            </Link>
            <Link className="footer-menu-item" to="/herramientas/calcular-psadt">
              Calcular PSADT
            </Link>
            <Link
              className="footer-menu-item"
              to="/casos-clinicos"
              onClick={e => loginModalHandler(e, "/casos-clinicos")}
            >
              Casos clínicos
            </Link>
          </div>
          <span className="text-desc">Aviso No. 203300202C4531</span>
          <div className="last-row col-sm-12">
            <div className="copyright">
              { new Date().getFullYear() } Asofarma - Todos los derechos reservados
            </div>
            <div className="terms">
              <Link to="/terminos-y-condiciones">Términos y condiciones</Link>
              <a href="http://www.asofarma.com.mx/Aviso_Privacidad.pdf" target="_blank">Aviso de privacidad</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
