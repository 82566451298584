import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import Context from "./../../context";
import axios from "axios";


function RegisterForm(props) {
  const { state, dispatch } = React.useContext(Context);
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [inputValues, setInputValues] = useState({
    name: null,
    fathers_lastname: null,
    mothers_lastname: null,
    profession: "Medicina General",
    identification: null,
    email: null,
    password: null,
    password_confirmation: null,
    can_be_notified: true,
    terms: false
  });

  let history = useHistory();
  if (state.isLogued) {
    history.push("/");
  }

  const onChangeHandler = e => {
    setInputValues({
      ...inputValues,
      [e.currentTarget.name]: e.currentTarget.type === 'checkbox' ? e.currentTarget.checked : e.currentTarget.value
    });
  };

  const register = e => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("/auth/register", { ...inputValues })
      .then(({ data }) => {
        setLoading(false);
        dispatch({
            type: "TOGGLE_REGISTER_MODAL",
            payload: { registerModalOpen: true }
        });
        setTimeout(() => {
            history.push("/");
        }, 1500);
        setErrors([]);
      })
      .catch(errors => {
        setLoading(false);
        const resp = errors.response.data.errors;
        const err = [];

        for (e in resp) {
          err.push(resp[e][0]);
        }
        setErrors(err);
      });
  };

  const hasErrors = errors ? (
    <div className="errors">
      {errors ? errors.map((i, k) => <div key={k}>{i}</div>) : null}
    </div>
  ) : null;

  return (
    <form className="animated fadeIn">
      <input
        type="text"
        placeholder="Nombre"
        name="name"
        onChange={onChangeHandler}
      />
      <div className="inp-50">
        <input
          type="text"
          placeholder="Apellido paterno"
          name="fathers_lastname"
          onChange={onChangeHandler}
        />
        <input
          type="text"
          placeholder="Apellido materno"
          name="mothers_lastname"
          onChange={onChangeHandler}
        />
      </div>
      <div className="inp-50">
        <select name="profession" value={inputValues.profession} onChange={onChangeHandler}>
          <option value="Medicina General">Medicina General</option>
          <option value="Urología">Urología</option>
          <option value="Oncología">Oncología</option>
          <option value="Especialista">Otra especialidad</option>
        </select>
        <input
          type="text"
          placeholder="Cédula"
          name="identification"
          onChange={onChangeHandler}
        />
      </div>
      <input
        type="email"
        placeholder="E-mail"
        name="email"
        onChange={onChangeHandler}
      />
      <input
        type="password"
        placeholder="Contraseña"
        name="password"
        onChange={onChangeHandler}
        style={{marginBottom: 0}}
      />
      <span className="hint">
          * El campo contraseña debe contener al menos 8 caracteres.
      </span>
      <input
        type="password"
        placeholder="Repetir contraseña"
        name="password_confirmation"
        onChange={onChangeHandler}
      />
      <div className="inp-check">
        <input
          type="checkbox"
          id="checkbox"
          name="can_be_notified"
          onChange={onChangeHandler}
          defaultChecked={true}
        />
        <label htmlFor="checkbox">
          Recibir novedades sobre nuevos artículos publicados.
        </label>
      </div>
      <div className="inp-check">
        <input
          type="checkbox"
          id="checkbox-terms"
          name="terms"
          onChange={onChangeHandler}
        />
        <label htmlFor="checkbox-terms">
          Acepto los <a href="/terminos-y-condiciones" target="_blank">Términos y condiciones de uso</a>
        </label>
      </div>
      <div className={loading ? 'spiner inline-block' : 'spiner d-none'}></div>
      {hasErrors}
      <button onClick={register}>Registrarse</button>
    </form>
  );
}

export default RegisterForm;
