import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Context from "./../../context";
import RegisterForm from "./register";
import LoginForm from "./login";
import Bground from "./../../assets/img/bg.jpg";
import Logo from "./../../assets/img/logo.svg";
import "./index.scss";

function Form() {
  const { state } = React.useContext(Context);
  const [toggleForm, setToggleForm] = useState(useLocation().pathname.includes('login'));

  let history = useHistory();
  const redirect = () => {
    if (state.isLogued) {
      history.push("/");
    }
  };
  

  const toggleFormHandler = e => {
    if (e.currentTarget.id === "login") {
      setToggleForm(true);
    }
    if (e.currentTarget.id === "register") {
      setToggleForm(false);
    }
  };

  return (
    <div className="form page">
      <div className="bg-wrap">
        <div
          className="bg-left"
          style={{ background: `url(${Bground}) right/cover` }}
        ></div>
        <div className="bg-right"></div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-6 logo-col">
            <Link to="/">
              <img src={Logo} alt="logo" />
            </Link>
          </div>
          <div className="col-sm-12 col-md-6 form-col">
            <div className="tab-btns">
              <div
                id="login"
                className={toggleForm ? "tab-btn active" : "tab-btn"}
                onClick={e => toggleFormHandler(e)}
              >
                Login
              </div>
              <div
                id="register"
                className={!toggleForm ? "tab-btn active" : "tab-btn"}
                onClick={e => toggleFormHandler(e)}
              >
                Registro
              </div>
            </div>
            <div
              id="login-form-cont"
              style={{ display: toggleForm ? "flex" : "none" }}
            >
              <LoginForm />
            </div>

            <div
              id="register-form-cont"
              style={{ display: !toggleForm ? "flex" : "none" }}
            >
              <RegisterForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Form;
