import React, { useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import Context from "./../../context";
import axios from "axios";

function LoginForm(props) {
  const { state, dispatch } = React.useContext(Context);

  const [errors, setErrors] = useState(null);

  const loginUser = useRef(null);
  const loginPass = useRef(null);

  let history = useHistory();
  const redirect = (to = "/") => {
    if (state.isLogued) {
      history.push(to);
    }
  };

  const logIn = (e) => {
    e.preventDefault();
    axios
      .post("/auth/login", {
        email: loginUser.current.value,
        password: loginPass.current.value,
        remember_me: false,
      })
      .then(({ data }) => {
        dispatch({
          type: "LOGIN",
          payload: {
            user: {
              id: data.user.id,
              name: data.user.information.name,
              lastname1: data.user.information.mothers_lastname,
              lastname2: data.user.information.fathers_lastname,
              dni: data.user.information.identification,
              job: data.user.information.profession,
              email: data.user.email,
              token: data.access_token,
            },
            token: data.access_token,
          },
        });

        redirect();
      })
      .catch((errors) => {
        const resp = errors.response.data.errors;
        const err = [];

        for (e in resp) {
          err.push(resp[e][0]);
        }
        setErrors(err);
      });
  };

  const hasErrors = errors ? (
    <div className="errors">
      {errors ? errors.map((i, k) => <div key={k}>{i}</div>) : null}
    </div>
  ) : null;

  return (
    <form className="animated fadeIn">
      <input type="email" placeholder="Usuario" ref={loginUser} />
      <input type="password" placeholder="Password" ref={loginPass} />
      <a
        href="https://dashboard.uroconexion.com.mx/password/reset"
        target="_blank"
      >
        Recuperar contraseña
      </a>
      {hasErrors}
      <button onClick={logIn}>Ingresar</button>
    </form>
  );
}

export default LoginForm;
