import React, { useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import Context from "./../../context";
import Hero from "./../../components/Hero";
import SidebarFeatured from "./../../components/Sidebar/Featured";
import RecentPosts from "./../../components/RecentPosts";
import PageHeading from "./../../components/PageHeading";
import "./index.scss";

function Home() {
  const { state, dispatch } = React.useContext(Context);

  useEffect(() => {
    dispatch({
        type: "SET_PAGE_POSTS",
        payload: { 
            pagePosts: [],
            pageCats: [],
            pagination: [],
            clipIcon: false,
        }
    });
      dispatch({
        type: "SET_PAGE_INFO",
        payload: { 
            apiBase: '/article',
            apiName: '/articulos',
            apiUrl: '',
            pageTitle: 'Artículos',
            pageIcon: ''
        }
    });
  }, []);

  return (
    <div className="home page">
      <Hero />
      <div className="container">
        <PageHeading />
        <div className="row">
          <div className="col-sm-12 col-lg-8">
            <RecentPosts title="Artículos" />
          </div>
          <div className="col-sm-12 col-lg-1"></div>
          <div className="col-sm-12 col-lg-3">
            <SidebarFeatured title="Artículos"/>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            {state.isLogued ? null : (
              <div className="register-no-logued">
                <div>
                  Regístrase para poder acceder <br /> a toda la información.
                </div>
                <Link to="/form/registro">Registro</Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Home);
