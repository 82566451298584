import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Context from "../../context";
import "./index.scss";
import arrowRed from "../../assets/img/icons/arrow-red.svg";
import arrowWhite from "../../assets/img/icons/arrow-white.svg";
import ClapGray from "../../assets/img/icons/clap-gray.svg";
import ClapRed from "../../assets/img/icons/clap-red.svg";
import PlayIcon from "../../assets/img/icons/play-trans.svg";

const PostCard = props => {
  const { state, dispatch } = React.useContext(Context);

  const [error, setError] = useState(null);
  const [popularity, setPopularity] = useState(props.popularity);
  const [votedAlready, setVotedAlready] = useState(false);

  const loginModalHandler = (e, to) => {
    if(props.is_public) {
        dispatch({
            type: "TOGGLE_MODAL",
            payload: { modalOpen: false }
        });
    } else {
        if (!state.isLogued) {
            e.preventDefault();
            dispatch({
                type: "TOGGLE_MODAL",
                payload: { modalOpen: true }
            });
        }
    }
  };

  const vote = (isLiked, postId) => {
    if (!isLiked && state.isLogued && !votedAlready) {
      axios
        .post("/article/like/" + postId, { type: true })
        .then(function(res) {
          setError(null);
          setVotedAlready(true);
          setPopularity(popularity + 1);
        })
        .catch(function(error) {
          setError("Se ha producido un error, por favor intente mas tarde.");
        });
    }
  };

  if (error)
    return (
      <div className="post-card animated fadeInLeft">
        <div className="errors">{error}</div>
      </div>
    );

  return (
    <div className="post-card animated fadeInLeft">
        <Link
            to={`/articulos/${props.title_slug}`}
            onClick={(e) => loginModalHandler(e, `/articulos/${props.title_slug}`)}
            className="title"
        >
        <img className="img-post" src={props.thumbnail} alt="img featured" />
        </Link>
        {state.isLogued &&
            <div
            className="reading-votes"
            onClick={() => vote(props.liked, props.id)}
            >
            <span>{props.reading_time_manual || props.reading_time}</span> |
            <img src={ClapGray} alt="" />
            <img src={ClapRed} alt="" />
            <span className="claps">{popularity}</span>
            {votedAlready || props.liked ? <span className="voted"> Ya votaste </span> : null}
            </div>

        }

      <Link
        to={`/articulos/${props.title_slug}`}
        onClick={(e) => loginModalHandler(e, `/articulos/${props.title_slug}`)}
        className="title"
      >
        <div
          dangerouslySetInnerHTML={{ __html: props.title }}
        />
      </Link>
      <div className="date">{props.created_at_readable}</div>
      <div
        className="excerpt"
        dangerouslySetInnerHTML={{ __html: props.description }}
      />
      <Link
        to={`/articulos/${props.title_slug}`}
        onClick={(e) => loginModalHandler(e, `/articulos/${props.title_slug}`)}
        className="see-more-btn"
      >
        <span>Ver más</span>
        <img src={arrowRed} alt="arrow" />
        <img src={arrowWhite} alt="arrow" />
      </Link>
    </div>
  );
};
export default PostCard;
