import React, {useState} from "react";
import moment from "moment";
import "./index.scss";
import "react-datepicker/dist/react-datepicker.css";
import Calendar from "../../../src/assets/img/icons/calendario.svg";
import axios from "axios";
import DatePicker, {registerLocale} from "react-datepicker";
import Esp from "date-fns/locale/es";
import Context from "../../context"; // the locale you want


registerLocale("esp", Esp); // register it with the name you want

function PsaCalculator(props) {
    const {state} = React.useContext(Context);
    const [startDate, setStartDate] = useState(new Date());
    const [psa, setPsa] = useState(0.1);
    const [infoToSend, setInfoToSend] = useState([]);
    const [results, setResults] = useState([]);
    const [errors, setErrors] = useState([]);

    const addHandler = () => {
        setErrors([]);
        const date = moment(startDate).format("YYYY-MM-DD");
        const item = {date, psa: +psa};

        const found = infoToSend.some(i => i.date === date || +i.psa === +psa);
        if (!found) {
            setInfoToSend([...infoToSend, item]);
        } else {
            setErrors([]);
            if (psa <= 0) {
                setErrors([
                    "Controle que el PSADT esté repetido ni sea menor o igual a 0."
                ]);
                setPsa(0.1);
            }
            setErrors([
                "Controle que la fecha ni el PSADT estén duplicados ni que el PSADT sea menor o igual a 0."
            ]);
        }
    };
    const removeHandler = (e, id) => {
        infoToSend.splice(id, 1);
        setInfoToSend([...infoToSend]);
    };
    const resetHandler = () => {
        setStartDate(new Date());
        setPsa(0.1);
        setInfoToSend([]);
        setResults([]);
        setErrors([]);
    };
    const calculateHandler = () => {
        setErrors([]);

        axios
            .post("/utils/psa", {records: infoToSend})
            .then(({data}) => setResults([data]))
            .catch(errors => setErrors(errors.response.data.errors.records));
    };

    const infoAdded = infoToSend.length
        ? infoToSend.map((i, k) => (
            <div className="psa-item animated fadeInLeft" key={k}>
                <span className="date">{moment(i.date).format("DD/MM/YYYY")}</span>
                <span className="psa">{+i.psa} +ng/ml</span>
                <span className="remove-btn-dsk" onClick={e => removeHandler(e, k)}>Remover</span>
                <span className="remove-btn-mob" onClick={e => removeHandler(e, k)}>x</span>
            </div>
        ))
        : null;

    const hasErrors = errors ? (
        <div className="errors">
            {errors ? errors.map((i, k) => <div key={k}>{i}</div>) : null}
        </div>
    ) : null;

    const finalResults = results.length ? (
        <div className="final-results">
            <div className="results-sub">Basado en tu información:</div>
            <div className="info-sent">
                <div className="info-sent-header">
          <span className="date">
            <strong>PSADT</strong> Fecha
          </span>
                    <span className="psa">
            <strong>PSADT</strong> Valor
          </span>
                </div>
                {infoToSend.map((i, k) => (
                    <div className="psa-item" key={k}>
                        <span className="date">{moment(i.date).format("DD/MM/YYYY")}</span>
                        <span className="psa">{+i.psa} +ng/ml</span>
                    </div>
                ))}
            </div>
            <hr/>
            <div className="results">
                <div className="results-sub">Resultados:</div>
                <div className="result-row-1">
                    <div className="title">
                        <span>Mes</span>
                    </div>
                    <div className="inner-row">
                        <span>Tiempo de duplicación</span>
                        <span>{results[0].doubling_time.months} meses</span>
                    </div>
                    <div className="inner-row">
                        <span>Velocidad</span>
                        <span>{results[0].velocity.month.toFixed(2)} ng/mL/mo</span>
                    </div>
                </div>
                <hr/>
                <div className="result-row-2">
                    <div className="title">
                        <span>Años</span>
                    </div>
                    <div className="inner-row">
                        <span>Tiempo de duplicación</span>
                        <span>{results[0].doubling_time.years} años</span>
                    </div>
                    <div className="inner-row">
                        <span> Velocidad </span>
                        <span>{results[0].velocity.year.toFixed(2)} ng/mL/yr</span>
                    </div>
                </div>
                <div className="reset-btn" onClick={resetHandler}>
                    Volver
                </div>
            </div>
        </div>
    ) : null;

    if (finalResults) return finalResults;

    if (!state.isLogued) {
        window.location.href = "/";
    }

    return (
        <div className="psa-calculator animated rotateInUpLeft">
            <div className="sub">Ingresa tu información:</div>
            <div className="inputs">
                <div className="date">
                    <div>Fecha</div>
                    <DatePicker
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                        locale="esp"
                        popperModifiers={{
                            preventOverflow: {
                                enabled: true,
                            },
                        }}
                    />{" "}
                    <img src={Calendar} alt="icon"/>
                </div>
                <div className="psa">
                    <div>PSADT</div>
                    <input
                        type="number"
                        step="any"
                        value={psa}
                        onChange={e => setPsa(e.currentTarget.value)}
                    />
                    ng/ml
                </div>
                <div className="add-btn-cont">
                    <div className="add-btn" onClick={addHandler}>
                        Agregar +
                    </div>
                </div>
            </div>
            <div className="info-to-send">{infoAdded}</div>
            <div className="calculator-btns">
                <div className="reset-btn" onClick={resetHandler}>
                    Limpiar
                </div>
                <div className="calculate-btn" onClick={calculateHandler}>
                    Calcular
                </div>
            </div>
            {hasErrors}
        </div>
    );
}

export default PsaCalculator;
