import React, { useState } from 'react';
import index from './index.scss';
import axios from "axios";

function EmailModal (props) {

    const [email, setEmail] = useState(null);
    
    const [error, setError] = useState(null);

    const validateEmail = () => {
        if(/\S+@\S+/.test(email)) {
            setError(null);
            props.clickSendHandler(email);
        } else {
            setError('Revise que haya escrito la dirección de email correctamente');
        }
    };

    return(
        <div className={props.isOpen ? "email-modal-backdrop is-open" : "email-modal-backdrop"} onClick={e => props.clickBackdropHandler()}>
            <div className="email-modal" onClick={e => e.stopPropagation()}>
                { !props.sent ?
                    <div>
                        <h2 className="title"> Enviar por correo </h2>
                        <input type="email" placeholder="Escribir e-mail destinatario" onChange={e => setEmail(e.currentTarget.value)} className={error ? 'fail' : null}/>
                    </div>
                    : 
                    <div>
                        <h2 className="title"> Mensaje enviado a: </h2>
                        <div> { email } </div>
                    </div>
                }
                <small>{ error }</small>
                { !props.sent ?
                    <button onClick={e => validateEmail()}> Enviar </button>
                    :
                    <button onClick={e => props.clickBackdropHandler()}> Cerrar </button> 
                }
            </div>
        </div>
    );

}

export default EmailModal;