import React,{ useState, useEffect } from "react";
import Context from "../../context";
import { withRouter } from "react-router-dom";
import PageHeading from "./../../components/PageHeading";
import PsaCalculator from "../../components/PsaCalculator";
import RecentArticles from "../../components/RecentPosts";
import CalculatorIcon from "../../assets/img/calculadora.svg";

import "./index.scss";

function Calculate(props) {
    const { state, dispatch } = React.useContext(Context);
    useEffect(() => {

        dispatch({
            type: "SET_PAGE_POSTS",
            payload: {
              pageCats: [],
            },
        });

      }, []);

  return (
    <div className="calculate page">
      <div className="container">
        <PageHeading />
        <div className="row">
          <div className="col-sm-12 col-lg-8">
            <h1>
              <img src={CalculatorIcon} alt="icon" />
              Calcular PSADT
            </h1>
            <small>Adaptado de <a href="https://www.mskcc.org/nomograms/prostate/psa_doubling_time" target="_blank">https://www.mskcc.org/nomograms/prostate/psa_doubling_time</a></small>
            <PsaCalculator />
            <RecentArticles />
          </div>
          <div className="col-sm-12 col-lg-1"></div>
          <div className="col-sm-12 col-lg-3">
            <div className="sidebar-psa animated fadeInRight">
              <h2>¿Para qué sirve esta herramienta?</h2>
              <p>
                El tiempo de duplicación del Antígeno Prostático Específico (PSADT) también llamado cambio en los niveles de PSA a través del tiempo, es un marcador predictivo para evaluar resultados del estado de la enfermedad en pacientes con Cáncer de Próstata (1). Dadas las diversas causas de mortalidad en los pacientes que tienen recurrencia bioquímica, la supervivencia puede verse disminuida debido a la presencia de metástasis o muerte por cáncer de próstata. Aquellos pacientes con PSADT corto tienen un alto riesgo de muerte. No todas la recurrencias bioquímicas  son clínicamente significativas, por lo tanto el PSADT se debe usar como una herramienta de utilidad para medir el de riesgo de muerte.(2)
              </p>
                <p>1. Philip M et al., Prostate-Specific Antigen Working Groups Guidelines on PSA Doubling Time. J Urol. 2008 June ; 179(6): 2181–2186. doi:10.1016/j.juro.2008.01.099.</p>
                <p>2. NCCN Guidelines V.2 2020</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Calculate);
