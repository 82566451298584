import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Context from "./../../../context";
import "./index.scss";
import axios from "axios";
import ClipIcon from "./../../../assets/img/icons/play-trans.svg";

const SidebarFeatured = props => {
  const { state, dispatch } = React.useContext(Context);
  const [articles, setArticles] = useState([]);
  const [error, setError] = useState(null);


  useEffect(() => {
    setError("cargando...");
    axios
      .get(`${state.apiBase}/featured`)
      .then(function(res) {
        setArticles(res.data);
        setError(null);
      })
      .catch(function(error) {
        setError("Se ha producido un error, por favor intente mas tarde.");
      });
  }, [state.apiBase]);
  
    const loginModalHandler = (e, art) => {
        if(art.is_public) {
            dispatch({
                type: "TOGGLE_MODAL",
                payload: { modalOpen: false }
            });
        } else {
            if (!state.isLogued) {
                e.preventDefault();
                dispatch({
                    type: "TOGGLE_MODAL",
                    payload: { modalOpen: true }
                });
            }
        }
    };


  const featured = articles.map((art, k) => (
    <Link
      to={`${state.apiName}/${art.title_slug}`}
      key={k}
      className={k === 0 ? "main" : "sec"}
      onClick={e => loginModalHandler(e,art)}
    >
      <div className="img-cont">
          { state.apiBase === '/clip' ? <img className="clip-icon" src={ClipIcon} alt="img icon" /> : null }
          <img src={art.thumbnail} alt="img featured" />
      </div>
      <div className="title">{art.title}</div>
      <span>{art.created_at_readable}</span>
    </Link>
  ));

  if (error) {
    return (
      <div className="error text-center py-5 animated fadeInRight">{error}</div>
    );
  } else {
    return (
      <div className="sidebar sidebar-featured animated fadeInRight">
        <div className="title-sidebar">
          <h4> {state.pageTitle} Destacados </h4>
        </div>
        <div className="content">{ featured }</div>
      </div>
    );
  }
};
export default SidebarFeatured;
